var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-grid-row",
    [
      _c(
        "gov-grid-column",
        { attrs: { width: "two-thirds" } },
        [
          _c("gov-heading", { attrs: { size: "l" } }, [_vm._v("Stop Words")]),
          _c("gov-body", [
            _vm._v(
              "\n      From this page, you can review the list of 'stop words' on the keyword\n      search. 'Stop words' are common and generic words that are used in a\n      number of different phrases and therefore would generate large numbers\n      of search results. By adding the words to this list, they will be\n      removed from a search query before it searches the database.\n    "
            )
          ]),
          _vm.loading
            ? _c("ck-loader")
            : _c("gov-button", { on: { click: _vm.onExportCsv } }, [
                _vm._v("Download stop words")
              ]),
          _c("gov-heading", { attrs: { size: "m" } }, [
            _vm._v("All stop words")
          ]),
          _vm.loading
            ? _c("ck-loader")
            : _c(
                "gov-list",
                { attrs: { bullet: "" } },
                _vm._l(_vm.stopWords, function(stopWord, index) {
                  return _c("li", { key: "StopWord-" + index }, [
                    _vm._v("\n        " + _vm._s(stopWord) + "\n      ")
                  ])
                }),
                0
              )
        ],
        1
      ),
      _vm.auth.isGlobalAdmin
        ? _c(
            "gov-grid-column",
            { attrs: { width: "one-third" } },
            [
              _c(
                "gov-button",
                { attrs: { to: { name: "stop-words-edit" }, expand: "" } },
                [_vm._v("Edit stop words")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }